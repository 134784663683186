<template>
<div class="pdf-item">
  <div class="period-title">
    <img :src="filesItem.image">
    <div>
      <h3><b>{{ filesItem.name }}</b></h3>
      <p>{{ filesItem.filesize }}</p>
    </div>
  </div>
  <div>
    <a :href="filesItem.file" download="download">
    <button class="download">
      下載
    </button>
    </a>
  </div>
</div>

</template>

<script>
export default {
  name: "periodicalItem",
  props: {
    filesItem: {
      type:Object, //返回对象
      default() {
        return {}
      }
    }
  },
  methods:{

  }
}
</script>

<style scoped>
.pdf-item{
  flex: 0 0 46%;
  max-width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #332D30;
  border-radius: 4px;
  padding: 20px
}

.period-title{
  display: flex;
  align-items: center;

}
.period-title img{
  margin-right:20px;
}
.period-title p{
  font-size: 12px;
}
.download{
  padding: 10px 16px;
  color: #fff;
  font-weight: bold;
  background: #332D30;
  border-radius: 4px;
  border: none;
  width: 62px;
}

@media only screen and (min-width:0px) and (max-width:991px) {

  .pdf-item {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }



}



</style>