<template>
  <div>
    <page-banner>
      <template #banner-txt>
        機構刊物
      </template>
      <template #banner-img>
        <img v-lazy="banner" class="img-center">
      </template>
    </page-banner>


    <div class="level-two tab-control">
      <div class="container"  style="padding-left: 0px;">
        <div class="level-two-container">
          <div class="tab-control" >

            <div class="tab-control-item">
              <router-link to="/administration">
                <span>行政中心</span>
                <span class="line"></span>
              </router-link>
            </div>


            <div class="tab-control-item active">
              <router-link to="/tokwawan">
                <span>土瓜灣中心</span>
                <span class="line"></span>
              </router-link>
            </div>

            <div class="tab-control-item">
              <router-link to="/cuiping">
                <span>翠屏中心</span>
                <span class="line"></span>
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </div>

    <periodical-list :filesList="filesList"></periodical-list>
    <loading-list ref="changeView" class="pdf-page"></loading-list>

  </div>
</template>

<script>
import pageBanner from "@/components/PageBanner";
import periodicalList from "@/components/content/periodical/periodicalList";
import loadingList from "@/components/loadingList";

import {getTokList} from "@/network/home";

export default {
  name: "ToKwaWan",
  components: {
    pageBanner,
    periodicalList,
    loadingList
  },
  data() {
    return{
      id: null,
      banner:{},
      filesList:[],
      page: 0,
    }
  },
  created() {
    this.getTokList()
  },
  mounted() {
    window.addEventListener("scroll", this.isRefresh, true);
  },
  methods:{

    isRefresh() {
      let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      let windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
      let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
      if (
          scrollTop + windowHeight >= scrollHeight - 600 &&
          this.isRefreshBool
      ) {
        this.isRefreshBool = false;
        this.getTokList();
      }
    },

    getTokList(){
      this.id = this.$route.params.id; //拿到id
      console.log(this.id)
      const page = this.page +1
      getTokList(this.id,page).then(res => {
        //
        this.banner = res.data.data.banner;

        if (page > 1){
          this.$refs.changeView.loading = true;
        }
        //判断
        const length = res.data.data.data.length;
        if (length > 0 ) {
          this.filesList.push(...res.data.data.data);
          this.page += 1;
          this.isRefreshBool = true;
        } else {
          console.log("没有数据了")
          this.$refs.changeView.noMore = true;
          this.$refs.changeView.loading = false;
        }
      })
    }
  },
}
</script>

<style scoped>

</style>