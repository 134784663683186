<template>
<div>
  <div class="Loading-more" v-if="loading">
    <div>
      <img src="@/assets/img/loading-list.gif">
      <p>加載中...</p>
    </div>
  </div>
  <div class="tips" v-if="noMore">
    <p>沒有更多數據了!</p>
  </div>
</div>
</template>

<script>
export default {
  name: "loadingList",
  data() {
    return {
      loading: false,
      noMore: false,
    }

  }
}
</script>

<style>


</style>