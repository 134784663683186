<template>
  <div class="common-bg padding-50">
    <div class="container">
      <div class="pdf-list">
        <periodical-item v-for="item in filesList" :key="item" :filesItem="item"></periodical-item>
      </div>
    </div>
  </div>
</template>

<script>
import periodicalItem from "@/components/content/periodical/periodicalItem";
export default {
  name: "periodicalList",
  components: {
    periodicalItem
  },
  props: {
    filesList: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style scoped>
.container{
  padding: 0;
}

.pdf-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media only screen and (min-width:0px) and (max-width:991px) {
  .container{
    padding: 0 15px;
  }
}



</style>